import Dexie from 'dexie';

export let SUPPORT_INDEXEDDB = false;
if (window.indexedDB) {
    SUPPORT_INDEXEDDB = true;
} else {
    SUPPORT_INDEXEDDB = false;
}

function MusicDatabase() {
    const db = new Dexie('Music');

    db.version(1).stores({
        music: '++id, *createTime, *musicId, *name, *artist, *times',
    });

    const table = {
        music: {
            id: null,
            createTime: null,
            musicId: null,
            name: null,
            artist: null,
            times: 0,
        }
    }

    /**
     * 获取歌曲列表
     * @returns {Promise<*>}
     */
    this.list = async () => {
        return await db.music.toArray();
    }

    this.getByMusicId = async (musicId) => {
        let entityList = await db.music
            .where('musicId').equals(musicId)
            .toArray();
        if (entityList === null) return null;
        if (entityList.length === 0) return null;
        return entityList[0];
    }

    /**
     * 保存或增加次数
     * @param musicId
     * @param name
     * @param artist
     * @returns {Promise<void>}
     */
    this.saveOrIncrease = async (musicId, name, artist) => {
        let entity = await this.getByMusicId(musicId);
        if (entity) {
            await this.increase(entity);
        } else {
            await this.save(musicId, name, artist);
        }
    }

    /**
     * 保存歌曲
     * @returns {Promise<void>}
     */
    this.save = async (musicId, name, artist) => {
        let gmtC = new Date().getTime();
        await db.music.add({
            createTime: gmtC,
            musicId: musicId,
            name: name,
            artist: artist,
            times: 1
        });
    }

    this.increase = async (entity) => {
        if (entity) {
            entity.times += 1;
            await db.music.put(entity);
        }
    }

    /**
     * 检查歌曲是否已存在
     * @param musicId 歌曲 id
     * @returns {Promise<boolean>}
     */
    this.checkIfExist = async (musicId) => {
        let entityList = await db.music
            .where('musicId').equals(musicId)
            .toArray();
        return entityList !== null && entityList.length > 0;
    }

    /**
     * 获取分页数据
     * @param {number} page 页码（从1开始）
     * @param {number} pageSize 每页条数
     * @returns {Promise<Array>}
     */
    this.getPageData = async (page, pageSize, reverse) => {
        const offset = (page - 1) * pageSize;
        let query = db.music.orderBy('times');
        if (reverse === null || reverse === undefined || reverse) {
            query = query.reverse();
        }
        return await query
            .offset(offset)
            .limit(pageSize)
            .toArray();
    }

    /**
     * 获取总记录数
     * @returns {Promise<number>}
     */
    this.getTotalCount = async () => {
        return await db.music.count();
    }

    /**
     * 删除歌曲
     * @param {Object} entity 要删除的实体
     * @returns {Promise<void>}
     */
    this.delete = async (entity) => {
        if (entity && entity.id) {
            await db.music.delete(entity.id);
        }
    }

}

export const music = new MusicDatabase();
