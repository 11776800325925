import Vue from 'vue'
import Vuex from 'vuex'
import {SUPPORT_INDEXEDDB, JusicFactory} from '../api/database.js';
import {client} from '../api/client.js';
import {music} from '../api/music.js';
import {log} from '../utils/log.js';
import {formatDate} from '../utils/object';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        socket: {
            socketClient: {},
            stompClient: {},
            stompHeaders: {},
            online: 0,
            isConnected: false,
            userName: localStorage.getItem("USER_NAME") ? localStorage.getItem("USER_NAME") : null,
            room: localStorage.getItem("ROOM") ? localStorage.getItem("ROOM") : "1",
            isRoot: false,
            isAdmin: false,
            enabledReconnection: localStorage.getItem('SOCKET_RECONNECTION_ENABLED'),
        },
        player: {
            pick: [],
            music: {},
            lyrics: [],
            lyric: '',
            volume: localStorage.getItem('VOLUME') ? Number(localStorage.getItem('VOLUME')) : 10,
            progress: 0,
            time: '00:00 / 00:00'
        },
        chat: {
            readyShow: false,
            data: [],
            message: '',
            enabledAutoScroll: true,
            unreadTotal: 0,
            scrollTop: 0,
            notification: {
                volume: 0,
                sound: {
                    name: null,
                    url: null,
                }
            }
        },
        search: {
            music: {
                keyword: '',
                count: 0,
                data: [],
                page: {
                    current: 1,
                    limit: 10,
                    count: 7,
                    total: 0
                },
            },
            picture: {
                keyword: '',
                count: 0,
                data: [],
            }
        },
        chatDatabase: {
            page: 0,
            size: 5,
            loadedTotal: 0,
            loading: false,
            noMoreMessage: false
        },
        room: {
            id: localStorage.getItem('ROOM') ? localStorage.getItem('ROOM') : '1',
            title: localStorage.getItem('ROOM_NAME') ? localStorage.getItem('ROOM_NAME') : '广场',
        },
        client: [],
        playlist: [],
    },
    getters: {
        getSocket: state => {
            return state.socket;
        },
        getPlayer: state => {
            return state.player;
        },
        getChat: state => {
            return state.chat;
        },
        getSocketClient: state => {
            return state.socket.socketClient;
        },
        getStompClient: state => {
            return state.socket.stompClient;
        },
        getStompHeaders: state => {
            return state.socket.stompHeaders;
        },
        getSocketOnline: state => {
            return Number(state.socket.online);
        },
        getIsConnected: state => {
            return Boolean(state.socket.isConnected);
        },
        getSocketUserName: state => {
            return String(state.socket.userName);
        },
        getSocketRoom: state => {
            return String(state.socket.room);
        },
        isSocketRoot: state => {
            return Boolean(state.socket.isRoot)
        },
        isSocketAdmin: state => {
            return Boolean(state.socket.isAdmin)
        },
        getEnabledReconnection: state => {
            return Boolean(state.socket.enabledReconnection);
        },
        getPlayerMusic: state => {
            return state.player.music;
        },
        getPlayerLyrics: state => {
            return state.player.lyrics;
        },
        getPlayerLyric: state => {
            return state.player.lyric;
        },
        getPlayerPick: state => {
            return state.player.pick;
        },
        getPlayerVolume: state => {
            return Number(state.player.volume);
        },
        getPlayerProgress: state => {
            return Number(state.player.progress);
        },
        getPlayerTime: state => {
            return String(state.player.time);
        },
        getChatData: state => {
            return state.chat.data;
        },
        getChatMessage: state => {
            return state.chat.message;
        },
        getSearchMusicKeyword: state => {
            return state.search.music.keyword
        },
        getSearchMusicData: state => {
            return state.search.music.data
        },
        getSearchMusicCount: state => {
            return state.search.music.count
        },
        getSearchMusicPageCurrent: state => {
            return state.search.music.page.current
        },
        getSearchMusicPageLimit: state => {
            return state.search.music.page.limit
        },
        getSearchMusicPageCount: state => {
            return state.search.music.page.count
        },
        getSearchMusicPageTotal: state => {
            return state.search.music.page.total
        },
        getSearchPictureKeyword: state => {
            return state.search.picture.keyword
        },
        getSearchPictureData: state => {
            return state.search.picture.data
        },
        getSearchPictureCount: state => {
            return state.search.picture.count
        },
        checkChatIfEnabledAutoScroll: (state) => {
            return state.chat.enabledAutoScroll;
        },
        getChatUnreadTotal: (state) => {
            return state.chat.unreadTotal;
        },
        getChatScrollTop: (state) => {
            return state.chat.scrollTop;
        },
        checkChatIfLoading: (state) => {
            return state.chatDatabase.loading;
        },
        checkChatIfNoMoreMessage: (state) => {
            return state.chatDatabase.noMoreMessage;
        },
        checkChatIfReadyShow: (state) => {
            return state.chat.readyShow;
        },
        getChatNotificationVolume: (state) => {
            return state.chat.notification.volume;
        },
        getChatNotificationSound: (state) => {
            return state.chat.notification.sound;
        },
        getRoomId: (state) => {
            return state.room.id;
        },
        getRoomTitle: (state) => {
            return state.room.title;
        },
        getClient: (state) => {
            return state.client;
        },
        getPlaylist: (state) => {
            return state.playlist;
        },
    },
    mutations: {
        setSocketClient: (state, value) => {
            state.socket.socketClient = value
        },
        setStompClient: (state, value) => {
            state.socket.stompClient = value;
        },
        setStompHeaders: (state, value) => {
            state.socket.stompHeaders = value;
        },
        setSocketOnline: (state, value) => {
            state.socket.online = value;
        },
        setSocketIsConnected: (state, value) => {
            state.socket.isConnected = value;
        },
        setSocketUserName: (state, value) => {
            state.socket.userName = value;
            window.localStorage.setItem('USER_NAME', value);
        },
        setSocketRoot: (state, value) => {
            state.socket.isRoot = Boolean(value)
        },
        setSocketAdmin: (state, value) => {
            state.socket.isAdmin = Boolean(value)
        },
        setEnabledReconnection: (state, value) => {
            state.socket.enabledReconnection = value;
            window.localStorage.setItem('SOCKET_RECONNECTION_ENABLED', value);
        },
        setPlayerMusic: (state, value) => {
            state.player.music = value;
        },
        setPlayerLyrics: (state, value) => {
            state.player.lyrics = value;
        },
        setPlayerLyric: (state, value) => {
            state.player.lyric = value;
        },
        setPlayerPick: (state, value) => {
            state.player.pick = value;
        },
        setPlayerVolume: (state, value) => {
            state.player.volume = value;
            window.localStorage.setItem('VOLUME', value);
        },
        setPlayerProgress: (state, value) => {
            state.player.progress = value;
        },
        setPlayerTime: (state, value) => {
            state.player.time = value;
        },
        pushChatData: (state, value) => {
            let room = state.room.id;
            let chatDatabase = JusicFactory.getDatabase(room).getChatRepository();
            chatDatabase.save(value).then(r => {
            });
            let createTimeFormat = formatDate(new Date().getTime());
            state.chat.data.push({
                type: value.type,
                sessionId: value.sessionId,
                nickName: value.nickName,
                content: value.content,
                images: value.images,
                createTimeFormat: createTimeFormat,
                clientId: value.clientId,
                roomId: value.roomId
            });
            // log.debug(`[store-pushChatData]`, value, 'chatDataList', state.chat.data);

            if (!state.chat.enabledAutoScroll) {
                state.chat.unreadTotal = state.chat.unreadTotal + 1;
                // log.debug(`[store-pushChatData] enable auto scroll: ${state.chat.enabledAutoScroll}, unread total: ${state.chat.unreadTotal}`);
            }
        },
        setChatMessage: (state, value) => {
            state.chat.message = value;
        },
        setChatNotificationVolume: (state, value) => {
            window.localStorage.setItem('CHAT_NOTIFICATION_VOLUME', value);
            state.chat.notification.volume = value;
        },
        setChatNotificationSound: (state, value) => {
            localStorage.setItem("CHAT_NOTIFICATION_SOUND_NAME", value.name);
            localStorage.setItem("CHAT_NOTIFICATION_SOUND_URL", btoa(value.url));
            state.chat.notification.sound = value;
        },
        setSearchMusicKeyword: (state, value) => {
            state.search.music.keyword = value
        },
        setSearchMusicData: (state, value) => {
            state.search.music.data = value
        },
        setSearchMusicCount: (state, value) => {
            state.search.music.count = value
        },
        setSearchMusicPageCurrent: (state, value) => {
            state.search.music.page.current = value
        },
        setSearchMusicPageLimit: (state, value) => {
            state.search.music.page.limit = value
        },
        setSearchMusicPageCount: (state, value) => {
            state.search.music.page.count = value
        },
        setSearchMusicPageTotal: (state, value) => {
            state.search.music.page.total = value
        },
        setSearchPictureKeyword: (state, value) => {
            state.search.picture.keyword = value
        },
        setSearchPictureData: (state, value) => {
            state.search.picture.data = value
        },
        setSearchPictureCount: (state, value) => {
            state.search.picture.count = value
        },
        setEnabledAutoScroll: (state, value) => {
            state.chat.enabledAutoScroll = value;
            if (state.chat.enabledAutoScroll) {
                state.chat.unreadTotal = 0;
                // log.debug(`[store-setEnabledAutoScroll] value: ${value}, set unread total: 0`);
            }
        },
        setChatScrollTop: (state, value) => {
            state.chat.scrollTop = value;
        },
        setChatIfLoading: (state, value) => {
            state.chatDatabase.loading = value;
        },
        setChatIfNoMoreMessage: (state, value) => {
            state.chatDatabase.noMoreMessage = value;
        },
        setRoomId: (state, value) => {
            state.room.id = value;
        },
        setRoomTitle: (state, value) => {
            state.room.title = value;
        },
        saveOrIncreasePickMusic: (state, value) => {
            if (!value) return;
            let musicId = value.id;
            let title = value.name;
            let artist = value.artist;
            music.saveOrIncrease(musicId, title, artist).then(() => {})
        },
        setPlaylist: (state, value) => {
            state.playlist = value;
            let room = state.room.id;
            let playlistRepository = JusicFactory.getDatabase(room).getPlaylistRepository();
            playlistRepository.saveAllSimpleList(state.playlist).then(_ => log.debug('setPlaylist success'));
        },
    },
    actions: {
        loadChatMessage: (ctx) => {
            let time = new Date().getTime();

            // 浏览器不支持 indexedDB
            if (!SUPPORT_INDEXEDDB) {
                ctx.state.chatDatabase.loading = false;
                ctx.state.chatDatabase.noMoreMessage = true;
                return;
            }

            ctx.state.chatDatabase.loading = true;
            // log.debug(`[store-loadChatMessage] start. time: ${time}`);

            let room = ctx.state.room.id;
            let chatDatabase = JusicFactory.getDatabase(room).getChatRepository();

            chatDatabase.count().then((c) => {
                let page = ctx.state.chatDatabase.page;
                let size = ctx.state.chatDatabase.size;
                let loadedTotal = ctx.state.chatDatabase.loadedTotal;

                let loadChatMessageByPage = () => {
                    // log.debug("[store-loadChatMessage] loadChatMessageByPage");
                    chatDatabase.load(page, size).then((dataList) => {
                        if (dataList && dataList.length > 0) {
                            dataList.reverse();

                            // let chatDataList = ctx.state.chat.data;
                            // ctx.state.chat.data = dataList;
                            // dataList.push(chatDataList);

                            for (let i = dataList.length - 1; i >= 0; i--) {
                                ctx.state.chat.data.unshift(dataList[i]);
                            }

                            ctx.state.chatDatabase.loadedTotal = ctx.state.chatDatabase.loadedTotal + dataList.length;
                            ctx.state.chatDatabase.page = ctx.state.chatDatabase.page + 1;

                            // log.debug(`[store-loadChatMessage] end. time: ${time}, dataList: ${JSON.stringify(dataList.map(item => {return {content: item.content}}))}, chatDataList: ${JSON.stringify(chatDataList.map(item => {return {content: item.content}}))}`);
                            // log.debug(`[store-loadChatMessage] end. time: ${time}, dataList: `, dataList.map(item => {
                            //     return {content: item.content}
                            // }), 'chatDataList', ctx.state.chat.data);
                        } else {
                            // log.debug(`[store-loadChatMessage] end. time: ${time}`);
                        }
                    });
                }

                let loadChatMessageById = () => {
                    // log.debug("[store-loadChatMessage] loadChatMessageById");
                    let data = ctx.state.chat.data;

                    if (data.length === 0) {
                        chatDatabase.loadBeforeId(null, size).then(r => {
                            handleReceivedMessage(r, true);
                        });
                    } else {
                        let firstMessageId = data[0].id;
                        chatDatabase.loadBeforeId(firstMessageId, size).then(r => {
                            handleReceivedMessage(r, true);
                        });
                    }
                }

                let handleReceivedMessage = (dataList, ifNeedReverse) => {
                    // log.debug(`[store-loadChatMessage-handleReceivedMessage] dataList: `, dataList);
                    if (dataList && dataList.length > 0) {
                        if (ifNeedReverse) dataList.reverse();

                        for (let i = dataList.length - 1; i >= 0; i--) {
                            let {raw, ...rest} = dataList[i];
                            rest.createTimeFormat = formatDate(rest.createTime);
                            ctx.state.chat.data.unshift(rest);
                        }
                        ctx.state.chatDatabase.loadedTotal = ctx.state.chatDatabase.loadedTotal + dataList.length;
                        ctx.state.chatDatabase.noMoreMessage = false;

                        // log.debug(`[store-loadChatMessage-handleReceivedMessage] end. time: ${time}, dataList: `, dataList.map(item => {
                        //     return {content: item.content}
                        // }), 'chatDataList', ctx.state.chat.data);
                    } else {
                        // log.debug(`[store-loadChatMessage-handleReceivedMessage] end. time: ${time}`);
                        ctx.state.chatDatabase.noMoreMessage = true;
                    }
                    ctx.state.chatDatabase.loading = false;
                }

                if (c <= loadedTotal) {
                    // log.debug(`[store-loadChatMessage] end. time: ${time}`);
                    ctx.state.chatDatabase.noMoreMessage = true;
                    ctx.state.chatDatabase.loading = false;
                } else {
                    loadChatMessageById();
                }

            });

        },

        cleanup: (ctx) => {
            // 清理消息列表
            ctx.state.chat.data = []
            ctx.state.chatDatabase.page = 0;
            ctx.state.chatDatabase.size = 5;
            ctx.state.chatDatabase.loadedTotal = 0;
            ctx.state.chatDatabase.noMoreMessage = false;
            ctx.state.chatDatabase.loading = false;
            ctx.state.chat.unreadTotal = 0;
            ctx.state.chat.enabledAutoScroll = true;
        },

        init: (ctx) => {
            client.list().then(r => {
                ctx.state.client = r;
            }).finally(() => {
                ctx.state.chat.readyShow = true;
            });
        },

        mountedRun: (ctx) => {
            let socketReconnectionEnabled = localStorage.getItem('SOCKET_RECONNECTION_ENABLED');
            if (socketReconnectionEnabled === undefined || socketReconnectionEnabled === null || socketReconnectionEnabled === '') {
                localStorage.setItem('SOCKET_RECONNECTION_ENABLED', true);
                ctx.state.socket.enabledReconnection = localStorage.getItem('SOCKET_RECONNECTION_ENABLED');
            }

            // init chat notification sound volume.
            let chatNotificationSoundVolume = localStorage.getItem("CHAT_NOTIFICATION_VOLUME");
            if (chatNotificationSoundVolume === undefined || chatNotificationSoundVolume === null || chatNotificationSoundVolume === '') {
                ctx.commit('setChatNotificationVolume', 0);
            } else {
                ctx.state.chat.notification.volume = chatNotificationSoundVolume;
            }

            // init chat notification sound.
            let chatNotificationSoundName = localStorage.getItem("CHAT_NOTIFICATION_SOUND_NAME");
            let chatNotificationSoundUrl = localStorage.getItem("CHAT_NOTIFICATION_SOUND_URL");
            const chatNotificationDefault = function() {
                // console.log('设置默认音效');
                let sound = {
                    name: '等噔噔',
                    url: '/audio/burn_complete.mp3'
                }
                localStorage.setItem("CHAT_NOTIFICATION_SOUND_NAME", sound.name);
                localStorage.setItem("CHAT_NOTIFICATION_SOUND_URL", btoa(sound.url));
                ctx.commit('setChatNotificationSound', sound);
            }
            if (chatNotificationSoundName === undefined || chatNotificationSoundName === null || chatNotificationSoundName === '' ||
              chatNotificationSoundUrl === undefined || chatNotificationSoundUrl === null || chatNotificationSoundUrl === '') {
                chatNotificationDefault();
            } else {
                // console.log('加载已设置音效');
                try {
                    ctx.commit('setChatNotificationSound', {
                        name: chatNotificationSoundName,
                        url: atob(chatNotificationSoundUrl),
                    });
                } catch (e) {
                    // console.error(e);
                    chatNotificationDefault()
                }
            }

        }
    },
    modules: {}
});
