import Dexie from 'dexie';

export let SUPPORT_INDEXEDDB = false;
export let CHAT_MESSAGE_EXPIRE_ENABLED = false;
export let CHAT_MESSAGE_MAX_NUMBER = 1000;
if (window.indexedDB) {
    SUPPORT_INDEXEDDB = true;
} else {
    SUPPORT_INDEXEDDB = false;
}

/**
 * jusic 库工厂，为适应多房间结构，特设计该方法进行分别处理
 * @type {{getInstance: (function(): {})}}
 */
export const JusicFactory = (function () {
    let instance;
    const JUSIC_ROOM_MAP = new Map();

    function createInstance() {
        // 创建实例的逻辑
        return {
            // 属性和方法
        };
    }

    function getDatabase(roomId) {
        if (roomId === undefined || roomId === null || roomId === '') {
            roomId = '1';
        }

        let jusic = JUSIC_ROOM_MAP.get(roomId);
        if (jusic === undefined || jusic === null) {
            jusic = new JusicDatabase(roomId);
            JUSIC_ROOM_MAP.set(roomId, jusic);
        }
        return jusic;
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
        getDatabase: function (roomId) {
            this.getInstance();
            return getDatabase(roomId);
        }
    };
})();

class JusicDatabase {
    constructor(roomId) {
        this.roomId = roomId;
        this.db = new Dexie(`Jusic_${roomId}`);

        this.db.version(1).stores({
            chat: '++id, *createTime, *sessionId, *nickName, *type, *content, *raw, *clientId, *roomId'
        });
        this.db.version(2).stores({
            chat: '++id, *createTime, *sessionId, *nickName, *type, *content, *raw, *clientId, *roomId',
            playlist: '++id, *createTime, *updateTime, *musicId, *name, *artist, *album, *duration, *channel, *type, *playlist',
        });

        this.chatRepo = new ChatRepository(this.db);
        this.playlistRepo = new PlaylistRepository(this.db);
    }

    // 提供访问聊天仓库的方法
    getChatRepository() {
        return this.chatRepo;
    }

    // 提供访问播放列表仓库的方法
    getPlaylistRepository() {
        return this.playlistRepo;
    }

}


// 聊天表操作仓库类
class ChatRepository {
    constructor(db) {
        this.db = db;
    }

    async list() {
        return await this.db.chat.toArray();
    }

    async listByUserId(userId) {
        return this.db.chat.where('userId').equals(userId).toArray();
    }

    async getById(id) {
        return await this.db.chat.get(id);
    }

    async save(entity) {
        // let no = chatRepository.convertToString(generate());
        let gmtC = new Date().getTime();
        await this.deleteOld();
        return this.db.chat.add({
            createTime: gmtC,
            sessionId: entity.sessionId,
            nickName: entity.nickName,
            type: entity.type,
            content: entity.content,
            raw: entity.raw,
            clientId: entity.clientId,
            roomId: entity.roomId
        });
    }

    async deleteOld() {
        if (!CHAT_MESSAGE_EXPIRE_ENABLED) {
            // message expire policy is not enabled.
            // log.debug("[chat-repository] chat message expire policy is not enabled.").
            return;
        }
        let count = await this.count();
        let overflowTimes = count - CHAT_MESSAGE_MAX_NUMBER;
        // log.debug(`[chat-repository] delete old data; data count: ${count}, overflow times: ${overflowTimes}`);
        if (overflowTimes > 0) {
            for (let i = 0; i < overflowTimes; i++) {
                let item = await this.db.chat.orderBy('id').first();
                if (item) {
                    await this.deleteById(item.id);
                    // log.debug(`[chat-repository] delete old data; delete time: ${i + 1}; id: ${item.id}`, item);
                }
            }
        }
    }

    async deleteById(id) {
        return this.db.chat.delete(id);
    }

    async load(page, size) {
        if (page === null || size === null) {
            return this.loadAll();
        }
        let offset = (page - 1) * size;
        // log.debug(`[db-load]: page: ${page}, size: ${size}, offset: ${offset}`);
        return this.db.chat.orderBy('id').reverse().offset(offset).limit(size).toArray();
    }

    async loadAll() {
        return await this.db.chat.toArray();
    }

    async loadBeforeId(id, size = 10) {
        if (id) {
            // log.debug(`[db-load]: loadBeforeId; id: ${id}, size: ${size}`);
            return this.db.chat.where('id').below(id).reverse().limit(size).toArray();
            // return db.chat.where('id').above(id).limit(size).toArray();
        }
        // log.debug(`[db-load]: loadBeforeId; size: ${size}`);
        return this.db.chat.orderBy('id').reverse().limit(size).toArray();
    }

    async getMaxSortByUserId(userId, parentFolderId) {
        let entityList = await this.db.chat
            .where('userId').equals(userId)
            .and(entity => entity.parentId === parentFolderId)
            .toArray();
        if (!entityList || entityList.length === 0) {
            return 0;
        }
        let max = entityList[0].sort;
        for (let entity of entityList) {
            if (entity.sort > max) {
                max = entity.sort;
            }
        }
        return max;
    }

    async count() {
        return this.db.chat.count();
    }

    convertToString(data) {
        if (data === undefined || data === null) return null;
        return String(data);
    }
}

// 播放列表表操作仓库类
class PlaylistRepository {
    constructor(db) {
        this.db = db;
    }

    async list() {
        return await this.db.playlist.toArray();
    }

    async getById(id) {
        if (!id) {
            throw new Error('id is required');
        }
        return await this.db.playlist.get(id);
    }

    async getByMusicId(musicId) {
        if (!musicId) {
            throw new Error('musicId is required');
        }
        return this.db.playlist.where('musicId').equals(musicId).first();
    }

    async listByMusicIds(musicIdList) {
        if (!Array.isArray(musicIdList)) {
            throw new Error('musicIdList must be an array');
        }
        if (musicIdList.length === 0) {
            return [];
        }

        return this.db.playlist.where('musicId').anyOf(musicIdList).toArray();
    }

    async getMapByMusicIds(musicIdList) {
        const entities = await this.listByMusicIds(musicIdList);
        return new Map(entities.map(entity => [entity.musicId, entity]));
    }

    async load(page = 1, size = 10) {
        if (page === null || size === null) {
            return this.loadAll();
        }
        let offset = (page - 1) * size;
        return this.db.playlist.orderBy('id').reverse().offset(offset).limit(size).toArray();
    }

    async loadAll() {
        return await this.db.playlist.toArray();
    }

    async loadBeforeId(id, size = 10) {
        if (id) {
            return this.db.playlist.where('id').below(id).reverse().limit(size).toArray();
        }
        return this.db.playlist.orderBy('id').reverse().limit(size).toArray();
    }

    async save(entity) {
        return this.db.playlist.add(entity);
    }

    async saveOrUpdateByMusic(entity) {
        if (!entity?.musicId) {
            throw new Error('musicId is required');
        }

        const existingEntity = await this.getByMusicId(entity.musicId);
        if (existingEntity) {
            // 如果存在则更新
            return this.updateByMusicId({
                ...entity
            });
        } else {
            // 如果不存在则新增
            return this.save(entity);
        }
    }

    async saveAllSimpleList(playlistIds) {
        if (!playlistIds || playlistIds.length === 0) {
            return;
        }
        const _this = this;
        let gmtC = new Date().getTime()
        await playlistIds.forEach(id => {
            _this.saveOrUpdateByMusic({
                updateTime: gmtC,
                musicId: id,
                channel: 'netease',
                name: null,
                type: '1'
            })
        })
    }

    async deleteById(id) {
        if (!id) {
            throw new Error('id is required');
        }
        return this.db.playlist.delete(id);
    }

    async deleteByMusicId(musicId) {
        if (!musicId) {
            throw new Error('musicId is required');
        }
        let entity = await this.getByMusicId(musicId);
        if (entity) {
            return this.deleteById(entity.id);
        }
        return null;
    }

    async update(entity) {
        if (!entity) {
            throw new Error('entity is required');
        }
        if (!entity.id) {
            throw new Error('entity id is required');
        }

        try {
            // console.log(`Updated playlist entity with id: ${entity.id}`);
            return await this.db.playlist.put(entity);
        } catch (error) {
            console.error('failed to update playlist entity:', error);
            throw error;
        }
    }

    async updateByMusicId(updateModel) {
        // 参数校验
        if (!updateModel?.musicId) {
            throw new Error('musicId is required');
        }
        if (Object.keys(updateModel).length <= 1) { // 只有 musicId
            return null;
        }

        const existingEntity = await this.getByMusicId(updateModel.musicId);
        if (!existingEntity) {
            return null;
        }

        // 过滤掉值为 null 的字段
        const filteredUpdateModel = Object.fromEntries(
            Object.entries(updateModel).filter(([_, value]) => value !== null)
        );

        // 如果没有有效更新字段
        if (Object.keys(filteredUpdateModel).length === 0) {
            return null;
        }

        // 只更新传入的非 null 字段
        const updatedEntity = {
            ...existingEntity,
            ...filteredUpdateModel
        };

        return this.update(updatedEntity);
    }


}

