export let log = {
    debug: {},
    info: {},
    warn: {},
    error: {},
};

let env = process.env.NODE_ENV;

log.debug = function (...args) {
    if (checkIfDebugMode()) {
        console.log(`%c${new Date().toISOString()} --- DEBUG: `, "color: #5c962c", args);
    }
};

log.info = function (...args) {
    if (checkIfDebugMode()) {
        console.log(`%c${new Date().toISOString()} ---  INFO: `, "color: #000000", args);
    }
};

log.warn = function (...args) {
    if (checkIfDebugMode()) {
        console.log(`%c${new Date().toISOString()} ---  WARN: `, "color: #a68a0d", args);
    }
};

log.error = function (...args) {
    if (checkIfDebugMode()) {
        console.log(`%c${new Date().toISOString()} --- ERROR: `, "color: #f0524f", args);
    }
};

function checkIfDebugMode() {
    return "development" === env || "test" === env;
}
